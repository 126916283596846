import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import Layout from '.'
import CallToAction from '../components/call-to-action'
import './layout.css'
import './bootstrap.scss'

const LayoutMDX = ({ children, pageContext }) => {
  const { title, description, path } = pageContext.frontmatter
  return (
    <Layout
      title={title}
      description={description}
      location={path}>
      <Container
        fluid
        style={{
          backgroundColor: '#1e1e1e',
          paddingLeft: '0',
          paddingRight: '0',
        }}
      >
        <Container
          style={{
            paddingTop: '3rem',
            paddingBottom: '3rem',
            backgroundColor: '#f8f8f8',
          }}
        >
          <h1 style={{ marginBottom: '3rem' }}>{title}</h1>
          {children}
        </Container>
        <CallToAction />
      </Container>
    </Layout>
  )
}

LayoutMDX.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.any
}

export default LayoutMDX
