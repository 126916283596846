import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout-mdx.jsx";
import ImageFluid from '../components/imageFluid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`Cửu-Long Võ-Đạo – La voie martiale des Neuf dragons`}</h3>
    <p>{`L’école des 9 Dragons (ou «Cửu-Long Võ-Đạo») tire son nom du plus grand fleuve
d’Asie, le Mekong, avec ses 4180 km.`}</p>
    <p>{`Il prend sa source au Tibet et traverse le Yunnan. Quittant alors le
territoire chinois, le fleuve suit la frontière entre le Laos et la Birmanie,
traverse Luang Prabang, puis forme la frontière entre le Laos et la Thaïlande
jusqu’au sud du Laos. Il traverse le Kampong Cham et Phnom Pênh au Cambodge. À
partir de là, il se devise en 9 branches, qui coulent vers 9 directions, parmi
lesquelles, 5 branches traversent les villes de Sadec, Vinh Long, My Tho, An
Giang, Can Tho et Ba Xuyen au sud du Vietnam. Enfin, il se déverse dans la mer
méridionale.`}</p>
    <ImageFluid src="photo-des-maitres-1972-vietnam.jpg" mdxType="ImageFluid" />
    <p>{`Par la forme de ses 9 branches, le Mekong est nommé Cửu-Long en Vietnamien soit
les 9 dragons.`}</p>
    <p>{`Les Cửu-Long ou 9 dragons ont la responsabilité de diffuser l’esprit et la
puissance de sa source mère vers les 9 directions du fleuve.`}</p>
    <p>{`La fédération Cửu-Long Võ-Đạo a été fondée, peu après le départ des troupes
japonaises de la péninsule du Sud-Est Asiatique, suite à leur capitulation
qui marqua la fin de la Seconde Guerre Mondiale.`}</p>
    <p>{`Cette fédération réunit Neuf célèbres Maîtres d’arts martiaux chinois et
vietnamiens, pour créer ce que l’on appelle « la Voie Martiale des Neuf
Dragons ».`}</p>
    <p>{`Chaque Maître apporta leur style et spécialité dans l’enseignement dispensé
dans un centre de formation pour futurs professeurs et experts à Saïgon (futur
Ho-Chi-Minh Ville). Près de 150 moniteurs s’y entraînaient chaque jour, triés
sur tous les clubs et écoles de ces Neuf Maîtres.`}</p>
    <ImageFluid src="historique-1970.png" mdxType="ImageFluid" />
    <p>{`Grâce au savoir commun de ses 9 Vénérables, le style de l’école devient complet
et diversifié. Les Neuf Sages se devaient ensuite de s’assurer leur succession,
pour qu’ainsi perdure leur savoir.`}</p>
    <h3>{`Le Cửu-Long Võ-Đạo en France`}</h3>
    <p>{`La première école CVLD en France a été fondé en 1981 à Nantes, par Maître Tran
Hoai-Ngoc et son épouse.`}</p>
    <ImageFluid src="Ngoc-khanh-dang-tap-1980.jpg" mdxType="ImageFluid" />
    <p>{`Durant plus de 11 ans, ils s’évertuèrent à faire connaître cet art martial
vietnamien (Vo-co-truyen, mais aussi appelé kung-fu vietnamien) sur la
Loire-Atlantique en démonstration mais aussi en compétition dans les
fédérations où l’école était inscrite. A la fin de cette période, M.Tran avait
alors créé 5 sites où on enseignait ce style d’arts martiaux, regroupant près
de 350 élèves, avec plus de 30 moniteurs certifiés.`}</p>
    <ImageFluid src="Gia-dinh-Ngoc-2006.jpg" mdxType="ImageFluid" />
    <p>{`En 1992, M. Tran et sa famille s’installent sur la Côte d’Azur, y créa sa
première école de Kung-Fu à Cannes, à Mougins, puis à Villefranche-sur-Mer en
1994 et enfin à Nice en 1995.
C’est à partir de cette époque que M. Tran a rajouté deux nouvelles disciplines
à son enseignement traditionnel : le Qi-Gong et le Tai-Chi-Chuan. En parallèle,
il enseigne les arts énergétiques issus de la Médecine Traditionnel Asiatique,
à savoir l’acupuncture, le massage énergétique et la gymnastique de longévité
(Qi-Gong énergétique).`}</p>
    <ImageFluid src="J0113082.jpg" mdxType="ImageFluid" />
    <p>{`Concernant l’école de Nice, son fils aîné, David, qui lui commença les arts
martiaux dès l’âge de 4 ans, créa sa première section de combat libre en 2004,
où il mixait déjà ses différentes formations en close combat, boxe thaï et
wing chun et grappling.
Bien avant ça, David commença à enseigner auprès de son père dès ses 16 ans
(1993), et passa ses différents grades et diplôme, pour enfin obtenir même le
diplôme de Vo-Su (Maître) en 2012. Son père lui laissa alors la direction de
l’école de Nice.
Puis l’école de Nice se diversifia un peu plus dans ses disciplines : création
d’une section d’art martial artistique, de Kali-Eskrima et même de sabre-laser.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      